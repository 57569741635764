const colors = {
  grey: {
    900: '#1f1f1f',
    800: '#262626',
    700: '#434343',
    650: '#595959',
    600: '#8c8c8c',
    550: '#bfbfbf',
    500: '#d9d9d9',
    400: '#f0f0f0',
    300: '#F5F5F5',
    200: '#FaFaFa',
    100: '#f9f9f9',
  },
  green: {
    500: '#00FFB0',
    400: '#00FFB0',
  },
  blue: {
    900: '#093945',
    800: '#135C6B',
    700: '#218291',
    600: '#34AAB7',
    500: '#56BDC4',
    400: '#7DCED1',
    300: '#A9DEDE',
    200: '#D8EBEA',
    100: '#E9F7F6',
  },
};

export default colors;
